import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutUser } from '@sagano/share/components';
import agentAuth from '@sagano/agent/vendors/firebase';
import { useStore } from '@sagano/agent/stores';

const UserView = () => {
  const { auth } = useStore();

  const logout = useCallback(() => {
    agentAuth.logout();
  }, []);

  return <LayoutUser auth={auth} onLogout={logout} />;
};

const User = observer(UserView);

export default User;
