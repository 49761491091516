import { action, computed } from 'mobx';
import { agentService } from '@sagano/agent/services';
import { GetStaffResp } from '@sagano/agent/services/types';
import { AgentStaffType } from '@sagano/share/types';
import { IRole } from '@sagano/share/const';
import { BaseAuth } from '@sagano/share/stores';

const storage = localStorage;
export class Auth extends BaseAuth {
  @action
  getUserDetail = async () => {
    return agentService.getStaff({ id: '0' }).then(res => {
      if (res) {
        try {
          storage.setItem(this.USER_DETAIL, JSON.stringify(res));
        } finally {
          this.setUserDetail(res);
        }
      }
    });
  };

  @computed
  get isPasswordAuth() {
    const userInfo = this.user?.providerData[0];
    return userInfo?.providerId === 'password';
  }

  @computed
  get isRootUser() {
    return (this.userDetail as GetStaffResp).isBelongsToRootGroup || false;
  }

  @computed
  get isAdmineUser() {
    return (this.userDetail as GetStaffResp).staffType === AgentStaffType.Admin;
  }

  @computed
  get isAgentAdmin() {
    return this.isRootUser && this.isAdmineUser;
  }
  @computed
  get isAgent() {
    return this.isRootUser;
  }
  @computed
  get role(): IRole {
    if (this.isRootUser && this.isAdmineUser) {
      // 代理店管理者
      return IRole.AGENT_ADMIN;
    } else if (this.isRootUser && !this.isAdmineUser) {
      // 代理店スタッフ
      return IRole.AGENT_STAFF;
    } else if (!this.isRootUser && this.isAdmineUser) {
      // 営業所管理者
      return IRole.GROUP_ADMIN;
    } else {
      // 営業所スタッフ
      return IRole.GROUP_STAFF;
    }
  }
}

export default new Auth();
