import { action, computed, makeObservable, observable } from 'mobx';
import { agentService } from '@sagano/agent/services';
import { GetAgentResp } from '@sagano/agent/services/types';

export class AgentSelf {
  constructor() {
    makeObservable(this);
  }
  @observable info: GetAgentResp = {};

  @action
  getAgentSelfInfo() {
    return agentService.getAgent().then(res => this.setInfo(res));
  }
  @action
  setInfo(info: GetAgentResp) {
    this.info = info;
  }
  @computed
  get agentGroups() {
    return (
      this.info.groups?.map(group => ({
        label: group.nameOrigin as string,
        value: group.id as string
      })) || []
    );
  }
  @computed
  get agentPaymentType() {
    return this.info.agentPaymentType;
  }
  @computed
  get agentType() {
    return this.info.agentType;
  }
  @computed
  get agentName() {
    return this.info.nameOrigin || this.info.nameJa;
  }
  @computed
  get agentSubGroupName() {
    const subGroup = this.info.groups?.[0];
    return subGroup?.nameOrigin || subGroup?.nameJa;
  }
  @computed
  get loaded() {
    return !!this.info.id;
  }
}

export default new AgentSelf();
