import React from 'react';
import auth, { Auth } from './modules/auth';
import reservation, { Reservation } from './modules/reservation';
import stations, { Stations } from './modules/stations';
import units, { Units } from './modules/units';
import bookingDetail, { BookingDetail } from './modules/bookingDetail';
import agentSelf, { AgentSelf } from './modules/agentSelf';

export type RootStore = {
  auth: Auth;
  reservation: Reservation;
  stations: Stations;
  units: Units;
  bookingDetail: BookingDetail;
  agentSelf: AgentSelf;
};

export const store: RootStore = {
  auth,
  reservation,
  stations,
  units,
  bookingDetail,
  agentSelf
};

const StoreContext = React.createContext<RootStore>(store);

export const StoreProvider = StoreContext.Provider;

export const useStore = () => React.useContext(StoreContext);

export { auth, reservation, stations, units, bookingDetail, agentSelf };
