import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Menu } from '@linktivity/link-ui';
import { isProd } from '@sagano/agent/utils/env';
import { useStore } from '@sagano/agent/stores';
import { AgentPaymentType } from '@sagano/share/const';
import styles from './sidebar.module.css';

const Sidebar = observer(() => {
  const { t } = useTranslation();
  const { agentSelf } = useStore();
  return (
    <aside className={styles.sidebar}>
      <div className={styles.inner}>
        <Menu className={styles.menu} accordion>
          <Menu.MenuItem label={t('views.dashboard.title')} to="/dashboard" />
          <Menu.SubMenu
            label={t('layouts.sidebar.bookingManager')}
            name="bookingManager"
          >
            <Menu.MenuItem
              label={t('share.notification.title')}
              to="/notifications"
            />
            <Menu.MenuItem
              label={t('views.booking.list.title')}
              to="/bookings"
            />
            <Menu.MenuItem
              label={t('share.layouts.sidebar.seatInquiry')}
              to="/search-allotments"
            />
          </Menu.SubMenu>
          <Menu.SubMenu
            label={t('share.common.salesManager')}
            name="salesManager"
          >
            <Menu.MenuItem label={t('views.sales.title')} to="/sales" />
            {!isProd &&
              agentSelf.agentPaymentType === AgentPaymentType.Coupon && (
                <Menu.MenuItem
                  label={t('share.monthlyReport.title')}
                  to="/monthly-report"
                />
              )}
          </Menu.SubMenu>
          <Menu.SubMenu
            label={t('layouts.sidebar.accountManager')}
            name="accountManager"
          >
            <Menu.MenuItem label={t('views.agent.title')} to="/agent" />
            <Menu.MenuItem
              label={t('share.password.changePassword')}
              to="/change-password"
            />
          </Menu.SubMenu>
        </Menu>
      </div>
    </aside>
  );
});

export default Sidebar;
