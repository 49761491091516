export default {
  lang: {
    en: '英語',
    ja: '日本語'
  },
  layouts: {
    sidebar: {
      bookingManager: '予約管理',
      accountManager: 'アカウント管理'
    }
  },
  views: {
    agent: {
      title: '代理店情報'
    },
    booking: {
      scheduledPayment: 'お支払い予定額',
      list: {
        title: '予約一覧/検索'
      },
      detail: {
        creditCardPayment: 'クレジット決済',
        reportCouponIssued: '発行',
        successToReportCouponIssued: '船車券を発行しました'
      }
    },
    dashboard: {
      title: 'ダッシュボード'
    },
    sales: {
      title: '売上/支払管理'
    },
    payment: {
      title: '支払確認中',
      success: '支払いが成功しました。'
    },
    login: {
      title: 'Welcome to Sagano Agent',
      otpTitle: '二要素認証',
      code: '認証コード',
      verify: '認証'
    }
  }
};
