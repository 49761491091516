export default {
  ABW: 'アルバ',
  AFG: 'アフガニスタン',
  AGO: 'アンゴラ',
  AIA: 'アンギラ',
  ALA: 'オーランド諸島',
  ALB: 'アルバニア',
  AND: 'アンドラ',
  ARE: 'アラブ首長国連邦',
  ARG: 'アルゼンチン',
  ARM: 'アルメニア',
  ASM: 'アメリカ領サモア',
  ATA: '南極',
  ATF: 'フランス領南方・南極地域',
  ATG: 'アンティグア・バーブーダ',
  AUS: 'オーストラリア',
  AUT: 'オーストリア',
  AZE: 'アゼルバイジャン',
  BDI: 'ブルンジ',
  BEL: 'ベルギー',
  BEN: 'ベナン',
  BES: 'ボネール、シント・ユースタティウスおよびサバ',
  BFA: 'ブルキナファソ',
  BGD: 'バングラデシュ',
  BGR: 'ブルガリア',
  BHR: 'バーレーン',
  BHS: 'バハマ',
  BIH: 'ボスニア・ヘルツェゴビナ',
  BLM: 'サン・バルテルミー',
  BLR: 'ベラルーシ',
  BLZ: 'ベリーズ',
  BMU: 'バミューダ',
  BOL: 'ボリビア多民族国',
  BRA: 'ブラジル',
  BRB: 'バルバドス',
  BRN: 'ブルネイ・ダルサラーム',
  BTN: 'ブータン',
  BVT: 'ブーベ島',
  BWA: 'ボツワナ',
  CAF: '中央アフリカ共和国',
  CAN: 'カナダ',
  CCK: 'ココス（キーリング）諸島',
  CHE: 'スイス',
  CHL: 'チリ',
  CHN: '中華人民共和国',
  CIV: 'コートジボワール',
  CMR: 'カメルーン',
  COD: 'コンゴ民主共和国',
  COG: 'コンゴ共和国',
  COK: 'クック諸島',
  COL: 'コロンビア',
  COM: 'コモロ',
  CPV: 'カーボベルデ',
  CRI: 'コスタリカ',
  CUB: 'キューバ',
  CUW: 'キュラソー',
  CXR: 'クリスマス島',
  CYM: 'ケイマン諸島',
  CYP: 'キプロス',
  CZE: 'チェコ',
  DEU: 'ドイツ',
  DJI: 'ジブチ',
  DMA: 'ドミニカ国',
  DNK: 'デンマーク',
  DOM: 'ドミニカ共和国',
  DZA: 'アルジェリア',
  ECU: 'エクアドル',
  EGY: 'エジプト',
  ERI: 'エリトリア',
  ESH: '西サハラ',
  ESP: 'スペイン',
  EST: 'エストニア',
  ETH: 'エチオピア',
  FIN: 'フィンランド',
  FJI: 'フィジー',
  FLK: 'フォークランド（マルビナス）諸島',
  FRA: 'フランス',
  FRO: 'フェロー諸島',
  FSM: 'ミクロネシア連邦',
  GAB: 'ガボン',
  GBR: 'イギリス',
  GEO: 'ジョージア',
  GGY: 'ガーンジー',
  GHA: 'ガーナ',
  GIB: 'ジブラルタル',
  GIN: 'ギニア',
  GLP: 'グアドループ',
  GMB: 'ガンビア',
  GNB: 'ギニアビサウ',
  GNQ: '赤道ギニア',
  GRC: 'ギリシャ',
  GRD: 'グレナダ',
  GRL: 'グリーンランド',
  GTM: 'グアテマラ',
  GUF: 'フランス領ギアナ',
  GUM: 'グアム',
  GUY: 'ガイアナ',
  HKG: '香港',
  HMD: 'ハード島とマクドナルド諸島',
  HND: 'ホンジュラス',
  HRV: 'クロアチア',
  HTI: 'ハイチ',
  HUN: 'ハンガリー',
  IDN: 'インドネシア',
  IMN: 'マン島',
  IND: 'インド',
  IOT: 'イギリス領インド洋地域',
  IRL: 'アイルランド',
  IRN: 'イラン・イスラム共和国',
  IRQ: 'イラク',
  ISL: 'アイスランド',
  ISR: 'イスラエル',
  ITA: 'イタリア',
  JAM: 'ジャマイカ',
  JEY: 'ジャージー',
  JOR: 'ヨルダン',
  JPN: '日本',
  KAZ: 'カザフスタン',
  KEN: 'ケニア',
  KGZ: 'キルギス',
  KHM: 'カンボジア',
  KIR: 'キリバス',
  KNA: 'セントクリストファー・ネイビス',
  KOR: '大韓民国',
  KWT: 'クウェート',
  LAO: 'ラオス人民民主共和国',
  LBN: 'レバノン',
  LBR: 'リベリア',
  LBY: 'リビア',
  LCA: 'セントルシア',
  LIE: 'リヒテンシュタイン',
  LKA: 'スリランカ',
  LSO: 'レソト',
  LTU: 'リトアニア',
  LUX: 'ルクセンブルク',
  LVA: 'ラトビア',
  MAC: 'マカオ',
  MAF: 'サン・マルタン（フランス領）',
  MAR: 'モロッコ',
  MCO: 'モナコ',
  MDA: 'モルドバ共和国',
  MDG: 'マダガスカル',
  MDV: 'モルディブ',
  MEX: 'メキシコ',
  MHL: 'マーシャル諸島',
  MKD: '北マケドニア',
  MLI: 'マリ',
  MLT: 'マルタ',
  MMR: 'ミャンマー',
  MNE: 'モンテネグロ',
  MNG: 'モンゴル',
  MNP: '北マリアナ諸島',
  MOZ: 'モザンビーク',
  MRT: 'モーリタニア',
  MSR: 'モントセラト',
  MTQ: 'マルティニーク',
  MUS: 'モーリシャス',
  MWI: 'マラウイ',
  MYS: 'マレーシア',
  MYT: 'マヨット',
  NAM: 'ナミビア',
  NCL: 'ニューカレドニア',
  NER: 'ニジェール',
  NFK: 'ノーフォーク島',
  NGA: 'ナイジェリア',
  NIC: 'ニカラグア',
  NIU: 'ニウエ',
  NLD: 'オランダ',
  NOR: 'ノルウェー',
  NPL: 'ネパール',
  NRU: 'ナウル',
  NZL: 'ニュージーランド',
  OMN: 'オマーン',
  PAK: 'パキスタン',
  PAN: 'パナマ',
  PCN: 'ピトケアン',
  PER: 'ペルー',
  PHL: 'フィリピン',
  PLW: 'パラオ',
  PNG: 'パプアニューギニア',
  POL: 'ポーランド',
  PRI: 'プエルトリコ',
  PRK: '朝鮮民主主義人民共和国',
  PRT: 'ポルトガル',
  PRY: 'パラグアイ',
  PSE: 'パレスチナ',
  PYF: 'フランス領ポリネシア',
  QAT: 'カタール',
  REU: 'レユニオン',
  ROU: 'ルーマニア',
  RUS: 'ロシア連邦',
  RWA: 'ルワンダ',
  SAU: 'サウジアラビア',
  SDN: 'スーダン',
  SEN: 'セネガル',
  SGP: 'シンガポール',
  SGS: 'サウスジョージア・サウスサンドウィッチ諸島',
  SHN: 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
  SJM: 'スヴァールバル諸島およびヤンマイエン島',
  SLB: 'ソロモン諸島',
  SLE: 'シエラレオネ',
  SLV: 'エルサルバドル',
  SMR: 'サンマリノ',
  SOM: 'ソマリア',
  SPM: 'サンピエール島・ミクロン島',
  SRB: 'セルビア',
  SSD: '南スーダン',
  STP: 'サントメ・プリンシペ',
  SUR: 'スリナム',
  SVK: 'スロバキア',
  SVN: 'スロベニア',
  SWE: 'スウェーデン',
  SWZ: 'エスワティニ',
  SXM: 'シント・マールテン（オランダ領）',
  SYC: 'セーシェル',
  SYR: 'シリア・アラブ共和国',
  TCA: 'タークス・カイコス諸島',
  TCD: 'チャド',
  TGO: 'トーゴ',
  THA: 'タイ',
  TJK: 'タジキスタン',
  TKL: 'トケラウ',
  TKM: 'トルクメニスタン',
  TLS: '東ティモール',
  TON: 'トンガ',
  TTO: 'トリニダード・トバゴ',
  TUN: 'チュニジア',
  TUR: 'トルコ',
  TUV: 'ツバル',
  TWN: '台湾',
  TZA: 'タンザニア',
  UGA: 'ウガンダ',
  UKR: 'ウクライナ',
  UMI: '合衆国領有小離島',
  URY: 'ウルグアイ',
  USA: 'アメリカ合衆国',
  UZB: 'ウズベキスタン',
  VAT: 'バチカン市国',
  VCT: 'セントビンセントおよびグレナディーン諸島',
  VEN: 'ベネズエラ・ボリバル共和国',
  VGB: 'イギリス領ヴァージン諸島',
  VIR: 'アメリカ領ヴァージン諸島',
  VNM: 'ベトナム',
  VUT: 'バヌアツ',
  WLF: 'ウォリス・フツナ',
  WSM: 'サモア',
  YEM: 'イエメン',
  ZAF: '南アフリカ',
  ZMB: 'ザンビア',
  ZWE: 'ジンバブエ'
};
