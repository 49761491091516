import { AdminMixer, AgentMixer, CommonMixer } from '@linktivity/sagano';
import { UnitAnnotationId, FareTypeId, AgentType } from './const';

export type { User, UserCredential } from '@linktivity/link-utils';

export interface Claims {
  name: string;
  email: string;
  user_id: string;
  user_group?: string;
  user_type: string;
}

export type ListPaymentsRespPayment = AdminMixer.Payment;
export type AgentSettlement = AdminMixer.AgentSettlement;
export type SettlementmanGroupSettlement =
  AdminMixer.SettlementmanGroupSettlement;
export type ListAgentSettlementsRequest =
  AdminMixer.ListAgentSettlementsRequest;
type UnitQuantityPriceItem = AdminMixer.UnitQuantityPriceItem;
type UnitQuantityPriceItemResp = AdminMixer.UnitQuantityPriceItemResp;
type ServiceOrdersHistoryRespServiceOrder =
  AdminMixer.ServiceOrdersHistoryRespServiceOrder;
type WheelChair = AdminMixer.WheelChair;
type ParticipantGroupReq = AdminMixer.ParticipantGroupReq;
type GetBookingDetailsResp = AdminMixer.GetBookingDetailsResp;
type ApplicantReq = AdminMixer.ApplicantReq;
type TourConductorReq = AdminMixer.TourConductorReq;
type GroupReq = AdminMixer.GroupReq;
type ServiceItem = AdminMixer.ServiceItem;
type ListUnitRespUnit = AdminMixer.ListUnitRespUnit;
type AgentmanGroupResp = AdminMixer.AgentmanGroupResp;
type AgentSummary = AdminMixer.AgentSummary;
type Agent = AdminMixer.GetAgentResp;
type Group = AdminMixer.AgentmanGroupResp;

export type ServiceOrdersReqServiceOrder =
  AdminMixer.ServiceOrdersReqServiceOrder;
export type Payment = AdminMixer.PaymentResp;
export type CalendarRespServiceDay = AdminMixer.GetCalendarRespServiceDay;

export type GetInventoryResp = AdminMixer.GetInventoryResp;
export type Summary = AdminMixer.Summary;

export type GetAgentResp = AgentMixer.GetStaffResp;
export type AdminUser = AdminMixer.User;
export type UserDetail = GetAgentResp | AdminUser;
export type Note = AdminMixer.GetNoteResp;
export type UpdateBookingParamsOperationRequest =
  AdminMixer.UpdateBookingParamsOperationRequest;
export type UpdateBookingSubParamsReq = AdminMixer.UpdateBookingSubParamsReq;

export type UpdateBookingMainParamsReq = AdminMixer.UpdateBookingMainParamsReq;
export type CalculateAmountResp = AdminMixer.CalculateAmountResp;
export type ListServiceOrdersByServiceRequest =
  AdminMixer.ListServiceOrdersByServiceRequest;
export type ListServiceOrdersByServiceResp =
  AdminMixer.ListServiceOrdersByServiceResp;

export type ServiceResp = AdminMixer.ServiceResp;

export type ListNotificationsResp = AdminMixer.ListNotificationsResp;
export type RequestbookingmanServiceOrder =
  AdminMixer.RequestbookingmanServiceOrder;
export type DirectionWithStations = CommonMixer.DirectionWithStations;
export type Station = CommonMixer.ListStationsResp;
export type ListStationsRespStation = CommonMixer.ListStationsRespStation;
export type Staff = AdminMixer.GroupStaffResp;
export type AddStaffReq = AdminMixer.AddStaffReq;
export type UpdateAgentGroupRequest = AdminMixer.UpdateAgentGroupRequest;
export type SearchServicesResp = AdminMixer.SearchServicesResp;

export type ServiceOrdersRespServiceOrder =
  AdminMixer.ServiceOrdersRespServiceOrder;

export type AgentStaffType = AdminMixer.AgentStaffType;
export const AgentStaffType = AdminMixer.AgentStaffType;

export type ArrangementType = CommonMixer.ArrangementType;
export type CreateBookingReq = AdminMixer.CreateBookingReq;

export type BookingMainParamsHistory = AdminMixer.BookingMainParamsHistoryResp;
export type BookingMainParamsResp = AdminMixer.BookingMainParamsResp;

export interface ISeat extends AdminMixer.CarInventoryArrangement {
  carId?: string;
  content?: React.ReactNode;
  disabled?: boolean;
  showArrangementType?: boolean;
}
export interface CarArrangement extends AdminMixer.CarInventoryArrangement {
  content?: React.ReactNode;
  disabled?: boolean;
  showArrangementType?: boolean;
}

export interface Car {
  logicalCarId?: string;
  physicalCarId?: string;
  arrangements?: CarArrangement[];
  standingCapacity?: number;
  standing?: AdminMixer.CarInventorySpecialSeat;
  physicalCarName?: string;
}

export type GetServiceAllotmentSummariesByServiceDayResp =
  AdminMixer.GetServiceAllotmentSummariesByServiceDayResp;

export type ServiceListWithDirection = AdminMixer.Direction;
export type GetServiceAllotmentSummariesByServiceDayRespService =
  AdminMixer.GetServiceAllotmentSummariesByServiceDayRespService;

export interface IOption {
  value: string;
  label: string;
}
export interface IOptionExtended extends IOption {
  disabled?: boolean;
}
interface IChangeStationReq {
  priority?: number;
  directionId?: string;
  fromStationId?: string;
  toStationId?: string;
}
interface IServiceItem extends ServiceItem, ServiceResp {
  serviceLabel?: string;
  fromStationLabel?: string;
  toStationLabel?: string;
}
interface IServiceOrder {
  isSelected?: boolean;
  priority?: number;
  services?: IServiceItem[];
}
interface IUnitHistory {
  from?: number;
  to?: number;
}
interface IUnitItem {
  quantity?: number;
  unitPrice?: number;
  subtotalAmount?: number;
  unitId?: string;
  annotation?: UnitAnnotationId;
  fareType?: FareTypeId;
  calculatedQuantity?: number;
  decrementFee?: number;
  decrementQuantity?: number;
  subtotalDecrementFee?: number;
  totalDecrementQuantity?: number;
  subtotalCancellationFee?: number;
}
interface IUnitItemHistory {
  quantity?: IUnitHistory;
  unitPrice?: IUnitHistory;
  subtotalAmount?: IUnitHistory;
  unitId?: string;
  annotation?: UnitAnnotationId;
  fareType?: FareTypeId;
  calculatedQuantity?: IUnitHistory;
  decrementFee?: IUnitHistory;
  decrementQuantity?: IUnitHistory;
  decrementFeeSubtotalAmount?: IUnitHistory;
}
interface IAnnotationNum {
  [UnitAnnotationId.Normal]?: IUnitItem;
  [UnitAnnotationId.Handicapped1]?: IUnitItem;
  [UnitAnnotationId.Handicapped2]?: IUnitItem;
  [UnitAnnotationId.Supporter]?: IUnitItem;
  [UnitAnnotationId.Free]?: IUnitItem;
}
interface IAnnotationNumHistory {
  [UnitAnnotationId.Normal]?: IUnitItemHistory;
  [UnitAnnotationId.Handicapped1]?: IUnitItemHistory;
  [UnitAnnotationId.Handicapped2]?: IUnitItemHistory;
  [UnitAnnotationId.Supporter]?: IUnitItemHistory;
  [UnitAnnotationId.Free]?: IUnitItemHistory;
}

export interface IBasciInfo {
  agentType?: AgentType;
  agentId?: string;
  agentSubGroupId?: string;
  agentName?: string;
  agentSubGroupName?: string;
  applicant?: ApplicantReq;
  tourConductor?: TourConductorReq;
  group?: GroupReq;
  submittedAt?: string;
  note?: string; // for create
  notes?: Note[]; // for detail
  bookingId?: string; // for update
  bookingToken?: string;
  waitListing?: boolean;
  operatorName?: string; // for detail
}
export interface IReservation extends CreateBookingReq {
  bookingId?: string;
  agentType?: AgentType;
  agentName?: string;
  agentSubGroupName?: string;
  serviceOrder: {
    serviceOrders: IServiceOrder[];
  };
  revision?: string;
  updateServiceOrder?: boolean;
  updateParticipantGroup?: boolean;
}

export type Mark = AdminMixer.Mark;
export const Mark = AdminMixer.Mark;

export const ArrangementState = AdminMixer.ArrangementState;

type IUnitByIdMap = Record<string, ListUnitRespUnit>;

interface IStation {
  stationId?: string;
  stationLabel?: string;
}

export type {
  Group,
  Agent,
  AgentSummary,
  AgentmanGroupResp,
  ListUnitRespUnit,
  UnitQuantityPriceItem,
  UnitQuantityPriceItemResp,
  ApplicantReq,
  TourConductorReq,
  GroupReq,
  GetBookingDetailsResp,
  ParticipantGroupReq,
  WheelChair,
  IServiceOrder,
  IUnitItem,
  IAnnotationNum,
  IServiceItem,
  IUnitByIdMap,
  ServiceOrdersHistoryRespServiceOrder,
  IUnitHistory,
  IUnitItemHistory,
  IAnnotationNumHistory,
  IChangeStationReq,
  IStation
};
