import { action } from 'mobx';
import { BookingDetail as BaseBookingDetail } from '@sagano/share/stores';
import { agentService } from '@sagano/agent/services';

export class BookingDetail extends BaseBookingDetail {
  @action async getDetail(bookingId: string) {
    return agentService.getBookingDetails({ bookingId }).then(res => {
      this.setBookingDetail(res);
    });
  }
  @action async listNote(bookingId: string) {
    return agentService.listNote({ bookingId }).then(res => {
      this.setNotes(res.notes || []);
    });
  }
}

export default new BookingDetail();
